.day-picker::-webkit-scrollbar {
    height: 8px;
    transition: all 3s ease;
}



.day-picker{
    transition: all 3s ease;
}

.day-picker::-webkit-scrollbar-thumb {
    background-color: teal;
    /* Change to your desired color */
    border-radius: 5px;
    transition: all 3s ease;
    /* Rounded thumb */
}

/* Style the scrollbar thumb on hover */
.day-picker::-webkit-scrollbar-thumb:hover {
    background-color: rgb(0, 182, 182);
    transition: all 3s ease;
    /* Change to your desired hover color */
}

/* Style the scrollbar track on hover */
.day-picker::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    transition: all 3s ease;
    /* Change to your desired track color */
}

