.delegate-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: #eee;
}

#delegatetable {
    width: 100%;
    height: 100%;
}

.delegate-container .user-panel {
    width: 40%;
    background-color: white;
    border-radius: 8px;
}

.delegate-container .stats-panel {
    height: 150px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    line-height: 0px;
    background-color: white;
    padding: 4px;
    border-radius: 8px;
}