.user-details{
    font-size: 25px;
    display: block;
    float: left;
    text-align: left;
    color: black;
}

.user-details h1{
    display: block;
    line-height: 30px;
}

.user-details h2{
    display: block;
    line-height: 10px;
    margin-left: 16px;
}