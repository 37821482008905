.resizable-table {
    padding: 0px;
    overflow: hidden;
    margin: 0px;
    height: 100%;
    flex: 1;
    flex-grow: 1;
    border-radius: 8px;
    display: flex;
    background-color: white;
    border: 1px solid #bbb;
    flex-direction: column;
}

.resizable-table th {
    border: none;
    padding: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.resizable-table td {
    border: none;
    padding: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    border-top: 1px solid #e2e2e2;
}

.resizable-table th {
    background-color: #f2f2f2;
}

.resizable-handler {
    background-color: #e2e2e2;
    height: 26px;
    cursor: e-resize;
    width: 3px;
    border-radius: 80px;
    transition: all 250ms ease-in;
}


.resizable-handler:hover {
    background-color: #ddd;
}