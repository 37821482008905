.circle-avatar{
    font-size: 45px;
    display: block;
    float: left;
    height: 100px;
    width: 100px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background-color:#3D7C98 ;
    font-weight: 900;
    line-height: 100px;
    text-align: center;
    color: white;
    margin: 16px;
}