.home-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: stretch;
}

.search-bar {
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 35px;
    margin-left: 8px;
}

.table-panel {
    width: 100%;
    display: flex;
    flex-grow: 1;
}

#hometable {
    width: 100%;
}