
.plan-container{
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: stretch;
    background-color: #eee;
    position: relative;
}

.table-panel{
    width: 100%;
    display: flex;
    height: calc(100% - 230px);
}

#plantable{
    width: 100%;
    height: 100%;
}

.plan-container
.user-panel{
    width: 40%;
    background-color: white;
    border-radius: 8px;
    flex-grow: 1;
    margin: 8px 0px;
}

.plan-container
.stats-panel{
    height: 150px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    line-height: 0px;
    background-color: white;
    padding: 4px;
    border-radius: 8px;
}

.plan-container
.stats-panel
.labels{
    color: black;
}
