.clients-pharmacy-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: stretch;
    background-color: #eee;
}


.search-bar {
    display: flex;
}

.clients-pharmacy-container .table-panel {
    width: 100%;
    display: flex;
    height: calc(100% - 140px);
}

.clients-pharmacy-container #clients-doctor-table {
    width: 70%;
    margin-right: 16px;
}


.stats-panel {
    display: flex;
    justify-content: space-between;
}

.stats-panel .labels {
    font-size: 17px;
    color: white;
    margin: 8px 16px;
}