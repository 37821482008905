.report-container{
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: stretch;
    background-color: #eee;
}

.report-container
.table-panel{
    width: 100%;
    display: flex;
    height: calc(100% - 148px);
}

.report-container
#reporttable{
    width: 100%;
    height: 100%;
}

.report-container
.user-panel{
    width: 50%;
    background-color: white;
    border-radius: 8px;
}

.stats-panel{
    display: flex;
    justify-content: space-between;
}

.stats-panel .labels{
    font-size: 17px;
    color:  black;
    margin: 8px 16px;
}