.profile {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    background-color: #eee;
}



.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    margin: 0px 16px;
    color: black;
}

.title h3 {
    font-size: 20px;
    line-height: 5px;
}

.title h4 {
    font-size: 17px;
    text-decoration: underline;
    cursor: pointer;
}