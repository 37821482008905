.side-menu {
  background-color: teal;
  height: 100%;
  color: white;
}


.side-menu .nav-link{
  color: #fff;
  transition: all 300ms ease;
}

Nav.Link:hover{
  background-color: rgb(0, 182, 182);
}

.logout-btn:hover{
  background-color: rgb(0, 170, 170);
}
